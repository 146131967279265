(function($) {
  'use strict'; // Start of use strict
  var $window = $(window);

  // jQuery for page scrolling feature - requires jQuery Easing plugin
  $(document).on('click', 'a.page-scroll', function(event) {
      var $anchor = $(this);
      $('html, body').stop().animate({
          scrollTop: ($($anchor.attr('href')).offset().top - 97)
      }, 1250, 'easeInOutExpo');
      event.preventDefault();
  });



  /**
  * COLLAPSIBLE PANEL
  */
  // ON LOAD
  var collapsePanelControl = $('.collapsible-panel-control');
  var collapsePanelSection = collapsePanelControl.parents('.page-section');
  var collapsePanelHeightWithPadding = collapsePanelSection.outerHeight();

  // CHECK COOKIE
  var cookieValue = readCookie('collapseStartState');
  if (typeof cookieValue === 'undefined' || cookieValue === null) {
    var collapseStartState = collapsePanelControl.attr('data-startstate');
  } else {
    var collapseStartState = cookieValue;
  }
  collapsePanelSection.addClass(collapseStartState);



  collapsePanelControl.on('click',function(e) {
    e.preventDefault();
    var section = $(this).parents('.page-section');
    if(section.hasClass('open-panel')) {
      bakeCookie ('collapseStartState','close-panel',0);
    } else if (section.hasClass('close-panel')) {
      bakeCookie ('collapseStartState','open-panel',0);
    }
    section.toggleClass('open-panel');
    section.toggleClass('close-panel');

  });


  /* SEND LINK EVENTS TO GOOGLE ANALYTICS */
  var filetypes = /\.(pdf|doc.*|xls.*|ppt.*|txt|mp3|mp4|m4a|wma|mov|avi|wmv|flv|wav|zip|rar|exe|dmg)$/i;
  var baseHref = '';
  if ($('base').attr('href') != undefined) {
    baseHref = $('base').attr('href');
  }


  $('a').on('click', function(event) {
    var pathArray = window.location.pathname.split( '/' );
    var category = pathArray[1];

    var el = $(this);
    var track = true;
    var href = (typeof(el.attr('href')) != 'undefined' ) ? el.attr('href') :'';

    // var isThisDomain = href.match(document.domain.split('.').reverse()[1] + '.' + document.domain.split('.').reverse()[0]);
    // console.log(isThisDomain);
    // console.log(href.match(/^https?\:/i));

    var isThisDomain = true;
    if (this.host !== window.location.host) {
      var isThisDomain = false;
    }

    if (!href.match(/^javascript:/i)) {
      var elEv = []; elEv.value=0, elEv.non_i=false;
      if (href.match(/^mailto\:/i)) {
        elEv.category = category;
        elEv.action = 'Email';
        // elEv.label = href.replace(/^tel\:/i, ''); // eamil address
        elEv.label = window.location.pathname; // page path
        elEv.loc = href;

      } else if (href.match(filetypes)) {
        var extension = (/[.]/.exec(href)) ? /[^.]+$/.exec(href) : undefined;
        elEv.category = category;
        elEv.action = 'Download ' + extension[0];
        elEv.label = href.replace(/ /g,'-');
        elEv.loc = baseHref + href;

      } else if (href.match(/^https?\:\/\/www\.pinterest\.com/i)) {
        elEv.category = category;
        elEv.action = 'Pinterest PinIt';
        // elEv.label = href.replace(/^https?\:\/\//i, '');
        elEv.label = el.parent().attr('data-asset');
        elEv.loc = href;
  // NEED OTHER TRACKING HERE


      } else if (href.match(/^https?\:/i) && !isThisDomain) {
        elEv.category = category;
        elEv.action = 'Outbound Link';
        elEv.label = href.replace(/^https?\:\/\//i, '');
        elEv.loc = href;

      } else if (href.match(/^tel\:/i)) {
        elEv.category = category;
        elEv.action = 'Click to Call';
        // elEv.label = href.replace(/^tel\:/i, ''); // phone number
        elEv.label = window.location.pathname; // page path
        elEv.loc = href;

      } else if (href.match(/^#wishlist/i)) {
        elEv.category = category;
        elEv.action = el.attr('data-action');
        elEv.label = el.attr('data-asset');
        elEv.loc = href;

      } else {
        track = false;
      }
    } else {
      // href="javascript:"
      track = false;
    }
    // console.log(track);
    // console.log(elEv);
    if (track) {
      // NOTE NAMED TRACKER FROM GTM
      ga('lh.send', 'event', elEv.category, elEv.action, elEv.label, elEv.value, elEv.non_i);

      if ( el.attr('target') == undefined || el.attr('target').toLowerCase() != '_blank') {
        setTimeout(function() { location.href = elEv.loc; }, 400);
        return false;
      }
    }
  });

  $('.nav-logo').hover(
    function() {
      $('.nav-logo, .nav-logo-hover').toggleClass('hide');
    },
    function() {
      $('.nav-logo, .nav-logo-hover').toggleClass('hide');
    }
  );

  $('.chat-icon').hover(
    function() {
      $('.chat-icon').toggleClass('hide');
    },
    function() {
      $('.chat-icon').toggleClass('hide');
    }
  );



  $('.featuredVideo').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.videoCarousel'
  });


  $('.videoCarousel').slick({
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: '.featuredVideo',
    dots: false,
    arrows: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  });


  // $('a').on('click', function(event) {
  //   event.preventDefault();
  //
  //   var el = $(this);
  //   var track = true;
  //   var link = (typeof(el.attr('href')) != 'undefined' ) ? el.attr('href') :'';
  //
  //   if (link.match(/^https?\:\/\/m2\.icarol\.com\/ConsumerRegistration\.aspx/i)) {
  //     var dt = new Date(); //current Date that gives us current Time also
  //     var startTime = '15:00:00';
  //     var endTime = '23:59:59';
  //     var s =  startTime.split(':');
  //     var dt1 = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(),
  //                        parseInt(s[0]), parseInt(s[1]), parseInt(s[2]));
  //
  //     var e =  endTime.split(':');
  //     var dt2 = new Date(dt.getFullYear(), dt.getMonth(),
  //                        dt.getDate(),parseInt(e[0]), parseInt(e[1]), parseInt(e[2]));
  //
  //     if (dt >= dt1 && dt <= dt2) {
  //       var link = link;
  //     } else {
  //       var link = '/resources/chat';
  //     }
  //   }
  //
  //   // console.log(link);
  //   if ( el.attr('target') == undefined || el.attr('target').toLowerCase() != '_blank') {
  //     window.open(link, '_parent');
  //   } else {
  //     window.open(link, '_blank');
  //   }
  //   return false;
  // });

function is_between_times( startTime, endTime ) {
  var dt = new Date(); //current Date that gives us current Time also
  // var startTime = '15:00:00';
  // var endTime = '23:59:59';
  var s =  startTime.split(':');
  var dt1 = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(),
                     parseInt(s[0]), parseInt(s[1]), parseInt(s[2]));

  var e =  endTime.split(':');
  var dt2 = new Date(dt.getFullYear(), dt.getMonth(),
                     dt.getDate(),parseInt(e[0]), parseInt(e[1]), parseInt(e[2]));

  if (dt >= dt1 && dt <= dt2) {
    var isTime = true;
  } else {
    var isTime = false;
  }

  return isTime;
}



  $('a').each(function() {

    var el = $(this);
    var link = (typeof(el.attr('href')) != 'undefined' ) ? el.attr('href') :'';

    if (link.match(/^https?\:\/\/m2\.icarol\.com\/ConsumerRegistration\.aspx/i)) {
      var dt = new Date(); //current Date that gives us current Time also
      var isTime = is_between_times( '15:00:00', '23:59:59' );

      if (isTime) {
        var link = link;
      } else {
        var link = '/resources/chat';
      }

      el.attr('href',link);
      // console.log(link);
    }

  });






  // STICKY
  $('.has-sticky-content').each(function(i,row) {
    var pad = 50;
    var rowTop = $(row).offset().top;
    var rowBottom = $(row).offset().top + $(row).outerHeight() - pad;
    var el = $(this).find('.is-fixable');
    var col = el.parent();
    el.width($(el).parent().width());
    col.parent().addClass('align-items-start');
    col.parent().removeClass('align-items-center');


    $window.scroll(function (event) {
      var scrollPosition = $(this).scrollTop();
      if (scrollPosition < rowTop - pad) {
        el.removeClass('is-fixed');
        col.parent().removeClass('align-items-end');
        col.parent().addClass('align-items-start');
      } else if ( (scrollPosition >= (rowTop - pad) ) && (scrollPosition <= (rowBottom - el.outerHeight() )) ) {
        el.addClass('is-fixed');
        el.css('top',pad);
      } else {
        el.removeClass('is-fixed');
        el.width($(el).parent().width());
        col.parent().removeClass('align-items-start');
        col.parent().addClass('align-items-end');
      }
    });
  //
  });


})(jQuery); // End of use strict

function bakeCookie(name,value,days) {
	if (days) {
		var date = new Date();
		date.setTime(date.getTime()+(days*24*60*60*1000));
		var expires = '; expires='+date.toUTCString();
	}	else {
    var expires = '';
  }
	document.cookie = name+'='+value+expires+'; path=/';
  // console.log('Mmmm.... smells scrumptious!');
}

function readCookie(name) {
	var nameEQ = name + '=';
	var ca = document.cookie.split(';');
	for (var i=0;i < ca.length;i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') {
      c = c.substring(1,c.length);
    }
		if (c.indexOf(nameEQ) == 0) {
      return c.substring(nameEQ.length,c.length);
    }
	}
  // console.log('Look ma... A Cookie!');
	return null;
}

function eatCookie(name) {
  // console.log('Yummm...That was delicious!');
	bakeCookie(name,'',-1);
}
